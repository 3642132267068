/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

require( './src/styles/global.css')


// Accessibility fix
exports.onRouteUpdate = ({ location, prevLocation }) => {
  document.getElementById('gatsby-focus-wrapper').setAttribute('role', 'application')
}